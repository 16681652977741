import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { themeGet } from "@styled-system/theme-get";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import * as GatsbyPluginModalRouting from "gatsby-plugin-modal-routing";

// share
import SharePlacesPageTemplate from "./share-places-page";
import SharePickupNewsPageTemplate from "./share-pickup-news-page";
import ShareHistoryPageTemplate from "./share-history-page";
import ShareInfomationPageTemplate from "./share-infomation-page";

// services
import SmoothScrolling from "./../services/anchorLink";
import isBlank from "../services/checkBlank";

// components
import SEO from "../components/seo";
import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import Breadcrumbs from "../components/Breadcrumbs";
// import ConditionalLayout from "../components/ConditionalLayout";
import {
  BgCrossInLayout,
  OuterBgCrossInLayout,
} from "../components/BgCrossInLayout";
import MakerText from "../components/Maker";
import IconButton from "../components/IconButton";
// import { HTMLContent } from "../components/NoticeContent";
import Content, { HTMLContent } from "../components/Content";

// images
import TopImage from "../img/top/image_004.png";
import AboutImage from "../img/top/about.png";
import FullImage from "../img/top/image_full.jpg";
import Image002 from "../img/top/image_002.png";
import MultiImage from "../img/share/image_multi.png";

// pdfs
import nikibiPDF from "../pdfs/medical_treatment/nikibi.pdf";

// styles
import {
  PickedUpCardWrapper,
  PickedUpCardItem,
  Card,
  PickedUpCardDescription,
  TitleH2Wrapper,
  TitleH2Text,
  PickedUpCardButtons,
  Capture,
  CardWrapper,
  CardItem,
  CardLabel,
  CardDescription,
  PointCardWrapper,
  PointCardItem,
  PointCardDescription,
  PointCardOuterLabel,
  TitleH3Wrapper,
  TitleH3Text,
  Description,
} from "./styles/index_page/style";
import CustomModal from "../components/CustomModal";
import ConditionalLayout from "../components/ConditionalLayout/ConditionalLayout";

export const IndexPageTemplate = ({
  about,
  popupPublish,
  noticeSection,
  noticeContent,
  contentComponent,
  image,
  title,
  heading,
  intro,
  contentSection,
  popupButtonRef,
}) => {
  const NoticeContent = contentComponent || Content;

  return (
    <div>
      {/*  */}
      {/* <CustomModal
        title="タイトル!!!!"
        description="ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。ここにテキストが入ります。"
      /> */}
      {popupPublish === true && (
        <p ref={popupButtonRef} className="js-modal-button">
          <GatsbyPluginModalRouting.Link
            onClick={() => console.log("clicked")}
            to="/index-popup/"
            asModal
            state={{
              noScroll: true,
            }}
          >
            {String(popupPublish)}
          </GatsbyPluginModalRouting.Link>
        </p>
      )}

      <section className=" l-section-lv2 u-sp-mt60">
        <div className="l-bg-cross-in-layout__outer">
          <div className="l-bg-cross-in-layout">
            <div className="l-bg-cross-in-layout__inner">
              <div className="c-pickup-card">
                <div className="c-pickup-card__item">
                  <div className="c-pickup-card__image">
                    <img src={TopImage} alt="" />
                  </div>
                </div>
                <div className="c-pickup-card__item">
                  <h2 className="c-pickup-card__title">
                    <span className="c-yellow-maker">{about.title}</span>
                  </h2>
                  <p className="c-pickup-card__description">
                    {about.description}
                  </p>
                </div>
              </div>
              <div className="c-center-buttons">
                <div className="c-center-buttons__inner">
                  <Link
                    to={"/#parts_of_trouble"}
                    className="c-button -border-button"
                    onClick={scrollToPartsOfTroubleSection}
                  >
                    <span className="c-button__text">美容診療の方はこちら</span>
                  </Link>

                  <Link
                    to={"/medical_hair"}
                    className="c-button -border-button"
                  >
                    <span className="c-button__text">医療脱毛の方はこちら</span>
                  </Link>
                </div>
              </div>
              <div className="c-center-buttons -one-column">
                <div className="c-center-buttons__inner">
                  <div className="c-button" onClick={scrollToMedicalSection}>
                    <span className="c-button__text">保険診療の方はこちら</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isBlank(noticeSection.title) ? (
        <></>
      ) : (
        <section className="l-section-lv2 l-main-content-hero u-pc-pt00">
          <div className="l-main-content-hero__inner">
            <h2 className="c-notice__title">
              <span className="c-yellow-maker">{noticeSection.title}</span>
            </h2>
            <div className={"c-notice-contents"}>
              <NoticeContent
                content={noticeContent}
                className={"c-notice-contents__inner"}
              />
            </div>
          </div>
        </section>
      )}

      <div
        className="full-width-image margin-top-0"
        style={{
          // backgroundImage: `url(${
          //   !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          // })`,
          backgroundImage: `url(${FullImage}`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundAttachment: `fixed`,
          width: "100%",
          height: 400,
        }}
      ></div>

      {/* <div className="l-main-cross-capture">
      <img className="l-main-cross-capture__image" src={FullImage} alt="" />
    </div> */}

      <section
        className="l-section-lv2 l-main-content-hero"
        id="medical_section"
      >
        <div className="l-main-content-hero__inner">
          <div className="c-box-title__outer">
            <div className="c-box-title">
              <h2 className="c-box-title__text">-CONTENT-</h2>
              <p className="c-box-title__description">保険診療</p>
            </div>
          </div>

          <p className="c-copy-text">
            当院はお客様に寄り添って、
            <span className="c-yellow-maker">安心して通える、環境創り</span>
            を行っています（保険診療は予約制ではありません）
          </p>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-主な疾患-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.contentSectionBlurbs.map((item) => {
                    return isBlank(item.url) | (item.url === "/") ? (
                      <div className="c-label-box-tile__list__item c-label">
                        <span className="c-label__link">{item.text}</span>
                      </div>
                    ) : (
                      <Link
                        to={item.url}
                        className="c-label-box-tile__list__item c-label"
                      >
                        <span className="c-label__link">{item.text}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-ニキビ-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.acneSectionBlurbs.map((acneItem) => {
                    return isBlank(acneItem.url) | (acneItem.url === "/") ? (
                      <div className="c-label-box-tile__list__item c-label">
                        <span className="c-label__link">{acneItem.text}</span>
                      </div>
                    ) : (
                      <Link
                        to={acneItem.url}
                        className="c-label-box-tile__list__item c-label"
                      >
                        <span className="c-label__link">{acneItem.text}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-肌トラブル-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.skinProblemsBlurbs.map((skinProblemItem) => {
                    return isBlank(skinProblemItem.url) |
                      (skinProblemItem.url === "/") ? (
                      <div className="c-label-box-tile__list__item c-label">
                        <span className="c-label__link">
                          {skinProblemItem.text}
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={skinProblemItem.url}
                        className="c-label-box-tile__list__item c-label"
                      >
                        <span className="c-label__link">
                          {skinProblemItem.text}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-爪トラブル-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.nailProblemsBlurbs.map((nailProblemItem) => {
                    return isBlank(nailProblemItem.url) |
                      (nailProblemItem.url === "/") ? (
                      <div className="c-label-box-tile__list__item c-label">
                        <span className="c-label__link">
                          {nailProblemItem.text}
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={nailProblemItem.url}
                        className="c-label-box-tile__list__item c-label"
                      >
                        <span className="c-label__link">
                          {nailProblemItem.text}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-できもの-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.blotchProblemsBlurbs.map(
                    (blotchProblemItem) => {
                      return isBlank(blotchProblemItem.url) |
                        (blotchProblemItem.url === "/") ? (
                        <div className="c-label-box-tile__list__item c-label">
                          <span className="c-label__link">
                            {blotchProblemItem.text}
                          </span>
                        </div>
                      ) : (
                        <Link
                          to={blotchProblemItem.url}
                          className="c-label-box-tile__list__item c-label"
                        >
                          <span className="c-label__link">
                            {blotchProblemItem.text}
                          </span>
                        </Link>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-その他-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.otherContentsBlurbs.map(
                    (otherContentsItem) => {
                      return isBlank(otherContentsItem.url) |
                        (otherContentsItem.url === "/") ? (
                        <div className="c-label-box-tile__list__item c-label">
                          <span className="c-label__link">
                            {otherContentsItem.text}
                          </span>
                        </div>
                      ) : (
                        <Link
                          to={otherContentsItem.url}
                          className="c-label-box-tile__list__item c-label"
                        >
                          <span className="c-label__link">
                            {otherContentsItem.text}
                          </span>
                        </Link>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="l-section-lv3">
            <div className="c-label-box-tile">
              <div className="c-label-box-tile__inner">
                <h3 className="c-label-box-tile__title">-アレルギー-</h3>
                <div className="c-label-box-tile__list">
                  {contentSection.allergyContentsBlurbs.map((allergyItem) => {
                    return isBlank(allergyItem.url) |
                      (allergyItem.url === "/") ? (
                      <div className="c-label-box-tile__list__item c-label">
                        <span className="c-label__link">
                          {allergyItem.text}
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={allergyItem.url}
                        className="c-label-box-tile__list__item c-label"
                      >
                        <span className="c-label__link">
                          {allergyItem.text}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="l-section-lv2 l-main-content-hero">
        <div className="l-main-content-hero__inner">
          <div className="c-box-title__outer">
            <div className="c-box-title">
              <h2 className="c-box-title__text">-OTHER-</h2>
              <p className="c-box-title__description">保険外診療</p>
            </div>
          </div>

          <section className="l-section-lv3 c-other-pickup-card__wrap">
            <div className="c-other-pickup-card__outer-title">-SERVICE 1-</div>
            <div className="c-other-pickup-card">
              <div className="c-other-pickup-card__item">
                <div className="c-other-pickup-card__image">
                  <img src={Image002} alt="" />
                </div>
              </div>
              <div className="c-other-pickup-card__item">
                <h3 className="c-other-pickup-card__title">
                  <span className="c-yellow-maker">医療脱毛</span>
                </h3>
                <p className="c-other-pickup-card__description">
                  当院は、皮膚の専門知識を持った皮膚科専門医が、厚生労働省認可の脱毛機を使用して処置を行います。
                </p>
                <div className="c-other-pickup-card__button">
                  <Link to={"/medical_hair"} className="c-button -sp-full">
                    <span className="c-button__text">医療脱毛の方はこちら</span>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <div className="c-label-box-tile" id="parts_of_trouble">
            <div className="c-label-box-tile__inner">
              <h3 className="c-label-box-tile__title">-お肌のお悩み-</h3>
              <div className="c-label-box-tile__list">
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/chloasma/ranking">
                    <span className="c-border-label__link__text">
                      大きなしみ(老人性色素斑等)
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/freckles/ranking">
                    <span className="c-border-label__link__text">
                      小さなしみ(雀卵斑等)
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/dullness/ranking">
                    <span className="c-border-label__link__text">
                      肝斑、くすみ
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link
                    className="c-border-label__link"
                    to="/slack-and-lift-up/ranking"
                  >
                    <span className="c-border-label__link__text">
                      たるみ・リフトアップ
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/gap-pore/ranking">
                    <span className="c-border-label__link__text">
                      毛穴の開き
                    </span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <Link className="c-border-label__link" to="/wrinkle/ranking">
                    <span className="c-border-label__link__text">しわ</span>
                  </Link>
                </div>
                <div className="c-label-box-tile__list__item c-border-label">
                  <a className="c-border-label__link" href={nikibiPDF}>
                    <span className="c-border-label__link__text">
                      ニキビ・ニキビ痕
                    </span>
                  </a>
                </div>

                {/* <div className="c-label-box-tile__list__item c-border-label">
                <Link className="c-border-label__link" to="/acne/ranking">
                  <span className="c-border-label__link__text">ニキビ</span>
                </Link>
              </div>
              <div className="c-label-box-tile__list__item c-border-label">
                <Link className="c-border-label__link" to="/">
                  <span className="c-border-label__link__text">ニキビ跡</span>
                </Link>
              </div>
              <div className="c-label-box-tile__list__item c-border-label">
                <Link className="c-border-label__link" to="/">
                  <span className="c-border-label__link__text">多汗症</span>
                </Link>
              </div>
              <div className="c-label-box-tile__list__item c-border-label">
                <Link className="c-border-label__link" to="/">
                  <span className="c-border-label__link__text">痩身・ダイエット</span>
                </Link>
              </div>
              <div className="c-label-box-tile__list__item c-border-label">
                <Link className="c-border-label__link" to="/">
                  <span className="c-border-label__link__text">AGA・女性脱毛症</span>
                </Link>
              </div> */}
              </div>
            </div>
          </div>

          <div className="c-label-box-tile" id="parts_of_trouble">
            <div className="c-label-box-tile__inner">
              <h3 className="c-label-box-tile__title">-その他のお悩み-</h3>
              <div className="c-label-box-tile__list">
                <div className="c-label-box-tile__list__item c-border-label">
                  <a
                    href="http://ultra-accent.plusmedicalesthetic.com/"
                    target="_blank"
                    rel="noopener"
                    className="c-border-label__link"
                  >
                    <span className="c-border-label__link__text">
                      ダイエット・痩身
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="c-full-capture">
            <img src={MultiImage} alt="" />
          </div>
        </div>
      </section>

      {/*  */}
      {/* <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`
      }}
    >
      <div
        style={{
          display: "flex",
          height: "150px",
          lineHeight: "1",
          justifyContent: "space-around",
          alignItems: "left",
          flexDirection: "column"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
            backgroundColor: "rgb(255, 68, 0)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              "rgb(255, 68, 0) 0.5rem 0px 0px, rgb(255, 68, 0) -0.5rem 0px 0px",
            backgroundColor: "rgb(255, 68, 0)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/products">
                      全ての製品をみる
                    </Link>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    最新のストーリー
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      {/*  */}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  ref: PropTypes.any,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  about: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  popupPublish: PropTypes.bool,
  noticeSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  contentSection: PropTypes.shape({
    contentSectionBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    acneSectionBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    skinProblemsBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    nailProblemsBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    blotchProblemsBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    otherContentsBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    allergyContentsBlurbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
};

const checkHash = () => {
  if (window.location.hash === "#medical-section") {
    SmoothScrolling.scrollTo("medical_section", null);
  }

  if (window.location.hash === "#parts-of-trouble") {
    SmoothScrolling.scrollTo("parts_of_trouble", null);
  }
};

const scrollToMedicalSection = () => {
  SmoothScrolling.scrollTo("medical_section", null);
};

const scrollToPartsOfTroubleSection = () => {
  SmoothScrolling.scrollTo("parts_of_trouble", null);
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { markdownRemark: post } = data;
  const crumbs = [
    {
      id: 1,
      name: "トップ",
      path: "./",
    },
    {
      id: 2,
      name: "医療脱毛",
      path: "./about",
    },
  ];
  const popupButtonRef = useRef(null);

  useEffect(() => {
    checkHash();
  });

  useEffect(() => {
    // console.log("=== ボタン ===");
    // console.log(popupButtonRef.current.firstElementChild.textContent);
    // let elements = document.getElementById("js-popup-title");
    // console.log(elements);
    // console.log(document.getElementsByClassName("c-popup-title"));

    if (!isBlank(popupButtonRef.current)) {
      popupButtonRef.current.firstElementChild.click();
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="【医療脱毛・全身脱毛】福岡天神で医療脱毛するなら天神皮ふ科"
        description="福岡の天神皮ふ科では、患者様の肌質に合わせた全身脱毛・医療脱毛の施術を行っております。問診～施術まで皮膚科専門医が担当し、質の高い脱毛をご提供するため厚生労働省認可の医療脱毛機を使用していますので、安心して施術を受けていただけます。"
        keywords="福岡市,皮膚科,天神皮ふ科,脱毛"
      />
      {/* <Breadcrumbs crumbs={crumbs} /> */}
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        intro={frontmatter.intro}
        about={frontmatter.about}
        popupPublish={frontmatter.popupPublish}
        noticeSection={frontmatter.noticeSection}
        noticeContent={post.html}
        contentComponent={HTMLContent}
        content={frontmatter.content}
        contentSection={frontmatter.contentSection}
        popupButtonRef={popupButtonRef}
      />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

IndexPage.propTypes = {
  popupButtonRef: PropTypes.any,
  contentComponent: PropTypes.func,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      id
      html
      frontmatter {
        title
        heading
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
        }
        about {
          title
          description
        }
        popupPublish
        noticeSection {
          title
        }
        contentSection {
          contentSectionBlurbs {
            text
            url
          }
          acneSectionBlurbs {
            text
            url
          }
          skinProblemsBlurbs {
            text
            url
          }
          nailProblemsBlurbs {
            text
            url
          }
          blotchProblemsBlurbs {
            text
            url
          }
          otherContentsBlurbs {
            text
            url
          }
          allergyContentsBlurbs {
            text
            url
          }
        }
      }
    }
  }
`;
